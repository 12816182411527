import {
  configureAxios,
  setupAxiosInterceptors as setupAxios,
  setupAxiosDefaults,
  getXSRFCookieNameForEnvironment,
} from '@ecosio/auth';
import {
  determineEnvironmentFromLocation,
  ErrorBoundary,
} from '@ecosio/components';
import {ConnectedIntlProvider} from '@ecosio/locales';
import axios, {isAxiosError} from 'axios';
import axiosBetterStacktrace from 'axios-better-stacktrace';
import axiosRetry, {exponentialDelay, isNetworkError} from 'axios-retry';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {Provider} from 'react-redux';
import AppRouter, {allowedPaths} from './AppRouter';
import store from './store';

import logger from './logger';
import {Loader, Message} from 'semantic-ui-react';

const debug = logger('App');
const queryClient = new QueryClient();

const BASE_URL = '/account';

const accountsErrorHandler = (error: unknown) => {
  if (
    isAxiosError(error) &&
    error?.response?.status === 403 &&
    error?.response?.headers?.mfa_enforced
  ) {
    return Promise.resolve(error);
  }
  return Promise.reject(error);
};

// axios breaks stack traces https://github.com/axios/axios/issues/2387
// this is fixed in 1.0.0-alpha ... https://github.com/axios/axios/releases/tag/v1.0.0-alpha.1
// should be removed once 1.0.0 is out
// https://github.com/axios/axios/pull/4624
axiosBetterStacktrace(axios);
// @ts-expect-error store type mismatch
setupAxios(store, allowedPaths, BASE_URL);
setupAxiosDefaults(axios);

const XSRF_HEADER = 'X-XSRF-TOKEN';
const csrfCookieName = getXSRFCookieNameForEnvironment();
const environment = determineEnvironmentFromLocation(window.location.href);

debug(`Setting csrf cookie name for ${environment} env to ${csrfCookieName}`);

configureAxios((authAxios) => {
  authAxios.defaults.baseURL = BASE_URL;
  authAxios.defaults.xsrfCookieName = csrfCookieName;
});

// Because our axios interceptor in the auth library treats 403 errors as any other client errors,
// we need to override the interceptor here and check whether 403 occured due to MFA enforced.
// In this case we don't treat the response as an error.
axios.interceptors.response.use((response) => response, accountsErrorHandler);

axios.defaults.baseURL = BASE_URL;
axios.defaults.xsrfCookieName = csrfCookieName;
axios.defaults.xsrfHeaderName = XSRF_HEADER;

// retry axios requests on network error
axiosRetry(axios, {
  retries: 3,
  retryDelay: exponentialDelay,
  retryCondition: isNetworkError,
});

const App = () => {
  return (
    <Provider store={store}>
      <ConnectedIntlProvider
        loaderElement={<Loader active />}
        errorElement={
          <Message error>
            <Message.Header>Failed to load.</Message.Header>
            <p>Please try reloading.</p>
          </Message>
        }>
        <QueryClientProvider client={queryClient}>
          <ErrorBoundary
            exceptionLogger={(error: unknown) => console.error(error)}
            axios={axios}>
            <AppRouter />
          </ErrorBoundary>
        </QueryClientProvider>
      </ConnectedIntlProvider>
    </Provider>
  );
};

export default App;
